import React from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {FieldInputProps} from 'formik'
import FormikErrors from './FormikErrors'

interface Props {
  formikFields: FieldInputProps<any>
  label: string
  type: string
  touched: boolean | undefined
  errors: string | undefined
  name?: string
  disable?: boolean
  required?: boolean
}

const CustomInput = ({errors, formikFields, touched, type, label, required, disable}: Props) => {
  const intl = useIntl()

  return (
    <>
      <div className='fv-row mb-3'>
        <label
          className={clsx('form-label', [
            {
              required: required,
            },
            {
              disabled: disable,
            },
          ])}
        >
          {intl.formatMessage({id: label})}
        </label>
        <input
          disabled={typeof disable != 'undefined' ? !disable : false}
          placeholder={intl.formatMessage({id: label})}
          type={type}
          autoComplete={type != 'password' ? 'off' : 'new-password'}
          {...formikFields}
          className={clsx('form-control ', {
            'is-invalid': touched && errors,
          })}
        />
        <FormikErrors errors={errors} touched={touched} />
      </div>
    </>
  )
}

export default CustomInput
