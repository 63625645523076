import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {DailyCheck} from '../../../../models/_dailyCheck'

interface Props {
  dc: DailyCheck
}

function round(num: number, decimalPlaces = 0): number {
  if (num < 0) return -round(-num, decimalPlaces)
  var p = Math.pow(10, decimalPlaces)
  var n = (num * p).toPrecision(15)
  return Math.round(Number(n)) / p
}

export function DcRecordModal({dc}: Props) {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [data, setData] = useState<{name: string; dc: number; amt: number}[]>([])
  const handleShow = () => setShow(true)
  const handleClose = () => {
    setShow(false)
  }
  const [ticks, setTicks] = useState<string[]>([])
  const [ticksY, setTicksY] = useState<number[]>([])

  useEffect(() => {
    if (show) {
      const data_temp: {name: string; dc: number; amt: number}[] = []
      if (dc.graphic_data) {
        dc.graphic_data.forEach((point, index) => {
          data_temp.push({name: ``, amt: index + 1, dc: point / 1000})
          setData(data_temp)
        })
      }
      let tempX = []
      for (let i = 0; i <= 30; i += 0.04) {
        tempX.push(round(i, 2) + 's')
      }
      setTicks(tempX)
      let tempY = []
      for (let i = -2; i <= 2; i += 0.1) {
        tempY.push(round(i, 1))
      }
      setTicksY(tempY)
    }
  }, [show, dc])

  return (
    <>
      {/* begin::Add Clinic */}
      <button type='button' className='btn btn-light-primary btn-sm' onClick={handleShow}>
        <i className='fa-solid fa-chart-line'></i>
      </button>
      {/* end::Add Clinic */}

      <Modal show={show} onHide={handleClose} size={'xl'}>
        <div className='modal-content '>
          <div className='modal-header'>
            <h3 className='card-title text-primary'>{intl.formatMessage({id: 'Gráfico'})}</h3>

            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={() => setShow(false)}
              aria-label='Close'
            >
              <span className='svg-icon svg-icon-1 text-dark'>X</span>
            </div>
          </div>
          <div className='modal-body  '>
            <div className='scroll h-500px'>
              <ResponsiveContainer height='100%' width='500%'>
                <LineChart
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray='0' stroke={'#e7c895'} fill={'#ffffff'} />
                  <XAxis dataKey='name' ticks={ticks} tickFormatter={() => ''} />
                  <YAxis
                    domain={[-2, 2]}
                    ticks={ticksY}
                    tickFormatter={(value, index) => {
                      return ''
                    }}
                  />
                  <Tooltip />
                  <Legend />
                  <Line type='monotone' dataKey='dc' stroke='#000000' dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
