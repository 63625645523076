import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {Prescription, Schedule} from '../../../../models/_schedule'
import {getSchedules} from '../../../../requests/_schedules'
import axios from 'axios'
import {PRESCRIPTIONS_STORE_URL} from '../../../_const'

interface Props {
  prescription: Prescription
  updateTable: (prescription: Prescription) => void
}
const getBgColor = (color: string): string => {
  switch (color) {
    case 'YES':
      return 'success'
    case 'NO':
      return 'danger'
    default:
      return 'warning'
  }
}

export function PrescriptionModal({prescription, updateTable}: Props) {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [data, setData] = useState<Schedule[]>([])
  const handleShow = () => setShow(true)
  const handleClose = () => {
    setShow(false)
  }

  useEffect(() => {
    const fetch = async () => {
      const data = await getSchedules('', prescription.id.toString()).then((d) => d.data)
      setData(data ?? [])
    }

    if (show) fetch()
  }, [show])
  const cancelPrescription = async () => {
    await axios.patch(`${PRESCRIPTIONS_STORE_URL}/cancel/${prescription.id}`)
    updateTable({...prescription, active: false})
    handleClose()
  }
  return (
    <>
      {/* begin::Add Clinic */}
      <button
        type='button'
        className='btn btn-light btn-active-light-primary btn-sm'
        onClick={handleShow}
      >
        <i className='fa-sharp fa-solid fa-eye text-primary p-0'></i>
      </button>
      {/* end::Add Clinic */}

      <Modal show={show} onHide={handleClose} size={'lg'}>
        <Modal.Header closeButton>
          <h3 className='card-title text-primary'>{intl.formatMessage({id: 'Prescrição'})}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className='w-100'>
            <div className='m-3'>
              <h5>{intl.formatMessage({id: 'Medicamento a tomar'})}</h5>
              <p className={'m-0'}>
                <strong>Nome:</strong> {prescription.medication.name}
              </p>
              <p className={'m-0'}>
                <strong>{intl.formatMessage({id: 'Dosagem:'})}</strong> {prescription.dosage}{' '}
                {prescription.label_dosage_unit}
              </p>
              <p className={'m-0'}>
                <strong>{intl.formatMessage({id: 'De'})}</strong> {prescription.start_at}
                <strong> {intl.formatMessage({id: 'até'})}</strong> {prescription.end_at}
              </p>
              <p className={'m-0'}>
                <strong>{intl.formatMessage({id: 'Descrição:'})}</strong>{' '}
                {prescription.medication.description}
              </p>

              <div className='d-flex flex-end m-0'>
                <p className={'m-0'}>
                  <strong>{intl.formatMessage({id: 'Receitado por:'})}</strong>{' '}
                  {prescription.responsible.full_name}
                </p>
              </div>
            </div>
          </div>
          <div className=' w-100 mt-2 h-500px scroll'>
            <div className='row'>
              {data.length > 0 ? (
                data.map((schedule) => {
                  return (
                    <div className='col-12 col-md-4 '>
                      <div
                        className={`rounded p-3 m-1 d-flex bg-light-${getBgColor(schedule.status)}`}
                      >
                        <div className='flex-fill flex-column '>
                          <span>{schedule.date}</span>
                          <br />
                          <span>{schedule.date_status ?? ''} </span>
                        </div>
                        <div className=''>
                          {schedule.status == 'WAITING' && (
                            <span className='badge badge-light-warning'>
                              <i
                                style={{fontSize: '2em'}}
                                className='fa-solid fa-stopwatch text-warning p-2'
                              ></i>
                            </span>
                          )}
                          {schedule.status == 'NO' && (
                            <span className='badge badge-light-danger '>
                              <i
                                style={{fontSize: '2em'}}
                                className='fa-solid fa-xmark text-danger p-2'
                              ></i>
                            </span>
                          )}
                          {schedule.status == 'YES' && (
                            <span className='badge badge-light-success '>
                              <i
                                style={{fontSize: '2em'}}
                                className='fa-solid fa-check text-success p-2'
                              ></i>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center mt-3'>
                    {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light' onClick={handleClose}>
            {intl.formatMessage({id: 'Close'})}
          </button>
          {!!prescription.active && (
            <button
              type='submit'
              className='btn btn-light-danger'
              onClick={() => cancelPrescription()}
            >
              {intl.formatMessage({id: 'Cancelar Prescrição'})}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}
