/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {EcgRecordModal} from '../../../../modals/EcgRecordModal'
import {Ecg} from '../../../../../../../models/_ecg'

type Props = {
  ecg: Ecg
}

const ActionsCell: FC<Props> = ({ecg}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return <EcgRecordModal ecg={ecg} />
}

export {ActionsCell}
