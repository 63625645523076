import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {DateClickArg} from '@fullcalendar/interaction'
import {useIntl} from 'react-intl'
import {dateToString, generalize} from '../../../_helpers'
import {customStyles, toastStyles} from '../../../_styles'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {
  APPOINTMENT_CATEGORIES_URL,
  APPOINTMENT_URL,
  PRIORITIES_URL,
  USERS_URL,
} from '../../../_const'
import {toast} from 'react-toastify'
import CustomInput from '../../_components/CustomInput'
import {Appointment} from '../../../../models/_appointment'
import {appointmentSchema} from '../../../../schemas/_appointment'
import {useParams} from 'react-router-dom'
import FormikTextArea from '../../_components/FormikTextArea'
import {User} from '../../../../models/_user'
import Select from 'react-select'
import {useThemeMode} from '../../../../_metronic/partials'
import {AppointmentCategory} from '../../../../models/_appointmentCategory'
import {Priority} from '../../../../models/_priority'
import ReactDatePicker from 'react-datepicker'
import CustomModalFooter from '../../_components/modals/CustomModalFooter'
import WarningBlock from '../../_components/inputs/WarningBlock'
import FormikErrors from '../../_components/FormikErrors'
import clsx from 'clsx'

interface Props {
  open: boolean
  updateOpen: () => void
  updateAppointment: (appointment: Appointment) => void
  date: DateClickArg
}

export function ScheduleAppointmentModal({open, updateOpen, date, updateAppointment}: Props) {
  const intl = useIntl()
  const {id} = useParams()
  const [show, setShow] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const [acs, setAcs] = useState<AppointmentCategory[]>([])
  const [aps, setAps] = useState<Priority[]>([])
  const [initialValues, setInitialValues] = useState<Appointment>({
    subject: '',
    location: '',
    observation: '',
    date_begin: '',
    date_end: '',
    user_id: '',
    appointmentCategory_id: '',
    appointmentPriority_id: '',
    responsible_id: id,
  } as Appointment)
  const {menuMode} = useThemeMode()

  useEffect(() => {
    const fetchData = async () => {
      if (date.date) {
        setInitialValues({...initialValues, date_begin: dateToString(date.date)})
        setShow(open)
      }
    }
    fetchData()
  }, [open])

  useEffect(() => {
    const fetchData = async () => {
      const response: AxiosResponse<{data: User[]}> = await axios.get(`${USERS_URL}/my-patients`)
      setUsers(response.data.data)
      const res_p: AxiosResponse<{data: Priority[]}> = await axios.get(`${PRIORITIES_URL}/all`)
      setAps(res_p.data.data)
      const res_ac: AxiosResponse<{data: AppointmentCategory[]}> = await axios.get(
        `${APPOINTMENT_CATEGORIES_URL}/all`
      )
      setAcs(res_ac.data.data)
    }
    fetchData()
  }, [])

  const handleClose = () => {
    updateOpen()
    formik.resetForm()
    setShow(false)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: appointmentSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${APPOINTMENT_URL}`, {
          ...values,
        })
        .then((res: {data: AxiosResponse<Appointment>}) => {
          toast.success(intl.formatMessage({id: 'Prescrição Criada'}), toastStyles)
          updateAppointment(res.data.data)
          handleClose()
          setSubmitting(false)
        })
        .catch((err) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      {show && (
        <Modal show={show} onHide={handleClose} size={'lg'}>
          <form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h3 className='card-title text-primary'>
                  {intl.formatMessage({id: 'Agendar Marcação'})}
                </h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row mb-2'>
                <WarningBlock
                  show={formik.isValid}
                  warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                />
                <div className='col-12 col-sm-6'>
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('subject')}
                    label={'Assunto'}
                    type={'text'}
                    touched={formik.touched.subject}
                    errors={formik.errors.subject}
                    name={'subject'}
                  />
                  <label className='form-label mb-0'>{intl.formatMessage({id: 'Categoria'})}</label>
                  <Select
                    options={acs}
                    getOptionLabel={(ac: AppointmentCategory) => ac.name}
                    getOptionValue={(ac: AppointmentCategory) => `${ac.id}`}
                    {...formik.getFieldProps('appointmentCategory_id')}
                    value={acs.find((value) => {
                      return `${value.id}` == `${formik.values.appointmentCategory_id}`
                    })}
                    onBlur={(event) => {
                      formik.setFieldTouched('appointmentCategory_id', true)
                      formik.setErrors({...formik.errors, appointmentCategory_id: undefined})
                    }}
                    onChange={(value) => {
                      formik.setFieldValue('appointmentCategory_id', `${value?.id}`)
                    }}
                    styles={customStyles(
                      menuMode,
                      formik.errors.appointmentCategory_id,
                      formik.touched.appointmentCategory_id
                    )}
                  />
                  <FormikErrors
                    errors={formik.errors.appointmentCategory_id}
                    touched={formik.touched.appointmentCategory_id}
                  />
                </div>
                <div className='col-12 col-sm-6'>
                  <label className='form-label mb-0'>{intl.formatMessage({id: 'Utente'})}</label>
                  <Select
                    options={users}
                    getOptionLabel={(user: User) => user.full_name}
                    getOptionValue={(user: User) => `${user.id}`}
                    {...formik.getFieldProps('user_id')}
                    value={users.find((value) => {
                      return `${value.id}` == `${formik.values.user_id}`
                    })}
                    onBlur={(event) => {
                      formik.setFieldTouched('user_id', true)
                      formik.setErrors({...formik.errors, user_id: undefined})
                    }}
                    onChange={(value) => {
                      formik.setFieldValue('user_id', `${value?.id}`)
                    }}
                    styles={customStyles(menuMode, formik.errors.user_id, formik.touched.user_id)}
                  />
                  <FormikErrors errors={formik.errors.user_id} touched={formik.touched.user_id} />
                  <label className='form-label fs-6  mt-3 m-0'>
                    {intl.formatMessage({id: 'Prioridade'})}
                  </label>
                  <Select
                    options={aps}
                    getOptionLabel={(ap: Priority) => ap.name}
                    getOptionValue={(ap: Priority) => `${ap.id}`}
                    {...formik.getFieldProps('appointmentPriority_id')}
                    value={aps.find((value) => {
                      return `${value.id}` == `${formik.values.appointmentPriority_id}`
                    })}
                    onBlur={(event) => {
                      formik.setFieldTouched('appointmentPriority_id', true)
                      formik.setErrors({...formik.errors, appointmentPriority_id: undefined})
                    }}
                    onChange={(value) => {
                      formik.setFieldValue('appointmentPriority_id', `${value?.id}`)
                    }}
                    styles={customStyles(
                      menuMode,
                      formik.errors.appointmentPriority_id,
                      formik.touched.appointmentPriority_id
                    )}
                  />
                  <FormikErrors
                    errors={formik.errors.appointmentPriority_id}
                    touched={formik.touched.appointmentPriority_id}
                  />
                </div>
              </div>
              <div className='row '>
                <div className='col-12 col-sm-6 d-flex flex-column gap-2'>
                  <label className='form-label mb-0'>
                    {intl.formatMessage({id: 'Data de inicio'})}
                  </label>
                  <ReactDatePicker
                    className={clsx('form-control', {
                      'is-invalid': formik.errors.date_begin && formik.touched.date_begin,
                    })}
                    placeholderText={
                      formik.values.date_begin == ''
                        ? 'Selecionar data...'
                        : formik.values.date_begin ?? 'Selecionar data...'
                    }
                    onChange={(date: Date) => {
                      formik.setFieldValue('date_begin', dateToString(date))
                    }}
                    selected={formik.values.date_begin ? new Date(formik.values.date_begin) : null}
                    showTimeSelect
                    timeFormat='HH:mm'
                    dateFormat='yyyy-MM-dd HH:mm'
                    popperPlacement='bottom'
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                  />
                  <FormikErrors
                    errors={formik.errors.date_begin}
                    touched={formik.touched.date_begin}
                  />
                </div>
                <div className='col-12 col-sm-6 d-flex flex-column gap-2'>
                  <label className='form-label mb-0'>
                    {intl.formatMessage({id: 'Data de fim'})}
                  </label>
                  <ReactDatePicker
                    className={clsx('form-control', {
                      'is-invalid': formik.errors.date_end && formik.touched.date_end,
                    })}
                    placeholderText={
                      formik.values.date_end == ''
                        ? 'Selecionar data...'
                        : formik.values.date_end ?? 'Selecionar data...'
                    }
                    onChange={(date: Date) => formik.setFieldValue('date_end', dateToString(date))}
                    selected={formik.values.date_end ? new Date(formik.values.date_end) : null}
                    minDate={new Date(formik.values.date_begin)}
                    showTimeSelect
                    timeFormat='HH:mm'
                    dateFormat='yyyy-MM-dd HH:mm'
                    popperPlacement='bottom'
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                  />
                  <FormikErrors errors={formik.errors.date_end} touched={formik.touched.date_end} />
                </div>
              </div>
              <div className='mt-3'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('location')}
                  label={'Local'}
                  type={'text'}
                  touched={formik.touched.location}
                  errors={formik.errors.location}
                  name={'location'}
                />
                <FormikTextArea
                  formikFields={formik.getFieldProps('observation')}
                  label={'Observação'}
                  touched={formik.touched.observation}
                  errors={formik.errors.observation}
                  name={'observation'}
                />
              </div>
            </Modal.Body>
            <CustomModalFooter
              isValid={formik.isValid}
              isSubmitting={formik.isSubmitting}
              handleClose={handleClose}
              closeLabel={'Close'}
              submitLabel={'Confirmar'}
            />
          </form>
        </Modal>
      )}
    </>
  )
}
