import React, {useEffect, useState} from 'react'
import {useOutletContext, useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {API_URL} from '../../../_const'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {BloodPressurePoint, BloodPressureWarning} from '../../../../models/_bloodPressure'
import CustomInput from '../../_components/CustomInput'
import {useFormik} from 'formik'
import {warningValuesSchema} from '../../../../schemas/_records'
import {toast} from 'react-toastify'
import {toastStyles} from '../../../_styles'
import {WarningValue} from '../../../../models/_warningRecords'
import {useIntl} from 'react-intl'
// @ts-ignore
import logo from '../../../assets/ecg.gif'
import {generalize, havePerm} from '../../../_helpers'
import {useAuth} from '../../../modules/auth'

function BloodPressurePage() {
  const intl = useIntl()
  const {id} = useParams()
  const {auth} = useAuth()
  const [data, setData] = useState<BloodPressurePoint[]>([])
  const interval: Date[] = useOutletContext()
  const [initialValues, setInitialValues] = useState<WarningValue>({} as WarningValue)
  const [warnings, setWarnings] = useState<{icon: string; bp: BloodPressureWarning; text: string}>({
    icon: 'face-smile',
    text: 'success',
    bp: {x: ''} as BloodPressureWarning,
  })

  useEffect(() => {
    const fetchData = async () => {
      const date_start: string =
        interval[0].getFullYear() +
        '-' +
        (interval[0].getMonth() + 1 >= 10
          ? interval[0].getMonth() + 1
          : '0' + (interval[0].getMonth() + 1)) +
        '-' +
        (interval[0].getDate() >= 10 ? interval[0].getDate() : '0' + interval[0].getDate())
      const date_end: string =
        interval[1].getFullYear() +
        '-' +
        (interval[1].getMonth() + 1 >= 10
          ? interval[1].getMonth() + 1
          : '0' + (interval[1].getMonth() + 1)) +
        '-' +
        (interval[1].getDate() >= 10 ? interval[1].getDate() : '0' + interval[1].getDate())
      const data_temp: BloodPressurePoint[] = []
      const res: AxiosResponse<BloodPressurePoint[]> = await axios.get(
        `${API_URL}/blood-pressures?user_id=${id}&date_start=${date_start} 00:00:00&date_end=${date_end} 23:59:59`
      )
      res.data.forEach((point, index) => {
        data_temp.push({...point})
      })
      setData(data_temp)

      if (havePerm('warningvalues:read', auth)) {
        const {data}: AxiosResponse<{data: WarningValue}> = await axios.get(
          `${API_URL}/warning-values/${id}`
        )
        setInitialValues(data.data)
      }

      if (res.data.length > 0) {
        showWarning(data_temp[data_temp.length - 1])
      }
    }
    if (interval[0] && interval[1]) fetchData()
  }, [interval])

  useEffect(() => {
    const fetchData = async () => {
      showWarning(data[data.length - 1])
    }
    fetchData()
  }, [initialValues])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: warningValuesSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${API_URL}/warning-values`, {
          dia_min: values.dia_min ?? null,
          dia_max: values.dia_max ?? null,
          sys_min: values.sys_min ?? null,
          sys_max: values.sys_max ?? null,
          pr_min: values.pr_min ?? null,
          pr_max: values.pr_max ?? null,
          user_id: id,
        })
        .then((res) => {
          setInitialValues({...values})
          toast.success(intl.formatMessage({id: 'Intervalo Definido'}), toastStyles)
          setSubmitting(false)
        })
        .catch((err) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  const showWarning = (point: BloodPressurePoint) => {
    const warns = {
      icon: 'face-smile',
      text: 'success',
      bp: {
        ...point,
        pr_icon: 'face-smile',
        pr_warning: 'success',
        sys_icon: 'face-smile',
        sys_warning: 'success',
        dia_icon: 'face-smile',
        dia_warning: 'success',
      },
    }
    if (point)
      if ((initialValues.pr_min ?? 0) > point.pr || (initialValues.pr_max ?? 2000) < point.pr) {
        warns.icon = 'face-frown'
        warns.text = 'danger'
        warns.bp.pr_icon = 'face-frown'
        warns.bp.pr_warning = 'danger'
      }
    if (point)
      if ((initialValues.dia_min ?? 0) > point.dia || (initialValues.dia_max ?? 2000) < point.dia) {
        warns.icon = 'face-frown'
        warns.text = 'danger'
        warns.bp.dia_icon = 'face-frown'
        warns.bp.dia_warning = 'danger'
      }
    if (point)
      if ((initialValues.sys_min ?? 0) > point.sys || (initialValues.sys_max ?? 2000) < point.sys) {
        warns.icon = 'face-frown'
        warns.text = 'danger'
        warns.bp.sys_icon = 'face-frown'
        warns.bp.sys_warning = 'danger'
      }
    setWarnings(warns)
  }

  return (
    <>
      {data.length !== 0 ? (
        <>
          {havePerm('warningvalues:read', auth) && data.length !== 0 && warnings.bp && (
            <div className='row m-0 m-md-11 mt-md-0'>
              <span>Data Selecionada: {warnings.bp.x}</span>
              <div className='col-12 col-md-4 mb-2'>
                <div className={`rounded shadow`}>
                  <div className='d-flex flex-start p-3 w-100'>
                    <i
                      className={`fa-solid fa-${warnings.bp.pr_icon}  text-${warnings.bp.pr_warning} m-2`}
                      style={{fontSize: '4em'}}
                    ></i>
                    <div className={'d-flex flex-column p-2'}>
                      <span
                        className={'text-uppercase text-muted'}
                        style={{fontSize: '1.25em', fontWeight: 700, lineHeight: 'normal'}}
                      >
                        {intl.formatMessage({id: 'Pulse rate'})}
                      </span>
                      <span style={{fontSize: '2em', fontWeight: 'bold', lineHeight: 'initial'}}>
                        {warnings.bp.pr}
                        <span style={{fontSize: '0.5em'}}> ms</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 mb-2'>
                <div className={`rounded shadow`}>
                  <div className='d-flex flex-start p-3 w-100'>
                    <i
                      className={`fa-solid fa-${warnings.bp.dia_icon}  text-${warnings.bp.dia_warning} m-2`}
                      style={{fontSize: '4em'}}
                    ></i>
                    <div className={'d-flex flex-column p-2'}>
                      <span
                        className={'text-uppercase text-muted'}
                        style={{fontSize: '1.25em', fontWeight: 700, lineHeight: 'normal'}}
                      >
                        {intl.formatMessage({id: 'Diastolic'})}
                      </span>
                      <span style={{fontSize: '2em', fontWeight: 'bold', lineHeight: 'initial'}}>
                        {warnings.bp.dia}
                        <span style={{fontSize: '0.5em'}}> mm Hg</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 mb-2'>
                <div className={`rounded shadow`}>
                  <div className='d-flex flex-start p-3 w-100'>
                    <i
                      className={`fa-solid fa-${warnings.bp.sys_icon}  text-${warnings.bp.sys_warning} m-2`}
                      style={{fontSize: '4em'}}
                    ></i>
                    <div className={'d-flex flex-column p-2'}>
                      <span
                        className={'text-uppercase text-muted'}
                        style={{fontSize: '1.25em', fontWeight: 700, lineHeight: 'normal'}}
                      >
                        {intl.formatMessage({id: 'Systolic'})}
                      </span>
                      <span style={{fontSize: '2em', fontWeight: 'bold', lineHeight: 'initial'}}>
                        {warnings.bp.sys}
                        <span style={{fontSize: '0.5em'}}> mm Hg</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='scroll h-300px mb-3'>
            <ResponsiveContainer
              height='100%'
              width={`${data.length < 100 ? '100' : data.length}%`}
              className={'scroll'}
            >
              <LineChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 0,
                  bottom: 5,
                }}
                onClick={(d) => {
                  if (d) {
                    showWarning(d.activePayload ? d.activePayload[0].payload : {})
                  }
                }}
              >
                <CartesianGrid strokeDasharray='2 2' />
                <YAxis />
                <Tooltip
                  content={({active, payload, label}) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className='d-flex flex-column flex-wrap bg-opacity-75 bg-white p-3'>
                          {payload[0].payload.x}
                          <span className='label'>{`Pr: ${payload[1].value}`}</span>
                          <span className='label'>{`Dia: ${payload[2].value}`}</span>
                          <span className='label'>{`Sys: ${payload[0].value}`}</span>
                        </div>
                      )
                    }

                    return null
                  }}
                />
                <Legend />
                <Line type='monotone' dataKey='sys' stroke='#009EF7' dot={false} />
                <Line type='monotone' dataKey='pr' stroke='#f7C619' dot={false} />
                <Line type='monotone' dataKey='dia' stroke='#f75719' dot={false} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        <>
          <div className='w-100 h-100 d-flex justify-content-center'>
            <img src={logo} alt='' />
          </div>
          <h3 className={'text-center text-muted w-100'}>
            {intl.formatMessage({id: 'Sem Registos'})}
          </h3>
        </>
      )}
      {havePerm('warningvalues:read', auth) && data.length !== 0 && (
        <div className='row'>
          <div className='col-12'>
            <hr />
            <h4>{intl.formatMessage({id: 'Avisos'})}</h4>
          </div>
          {havePerm('warningvalues:update', auth) && (
            <form onSubmit={formik.handleSubmit}>
              <div className='row'>
                <div className='col-6 col-md-2'>
                  <CustomInput
                    formikFields={formik.getFieldProps('pr_min')}
                    label={'PR Min'}
                    type={'number'}
                    touched={formik.touched.pr_min}
                    errors={formik.errors.pr_min}
                    name={'pr_min'}
                  />
                </div>
                <div className='col-6 col-md-2'>
                  <CustomInput
                    formikFields={formik.getFieldProps('pr_max')}
                    label={'PR Max'}
                    type={'number'}
                    touched={formik.touched.pr_max}
                    errors={formik.errors.pr_max}
                    name={'pr_max'}
                  />
                </div>
                <div className='col-6 col-md-2'>
                  <CustomInput
                    formikFields={formik.getFieldProps('dia_min')}
                    label={'DIA Min'}
                    type={'number'}
                    touched={formik.touched.dia_min}
                    errors={formik.errors.dia_min}
                    name={'dia_min'}
                  />
                </div>
                <div className='col-6 col-md-2'>
                  <CustomInput
                    formikFields={formik.getFieldProps('dia_max')}
                    label={'DIA Max'}
                    type={'number'}
                    touched={formik.touched.dia_max}
                    errors={formik.errors.dia_max}
                    name={'dia_max'}
                  />
                </div>
                <div className='col-6 col-md-2'>
                  <CustomInput
                    formikFields={formik.getFieldProps('sys_min')}
                    label={'SYS Min'}
                    type={'number'}
                    touched={formik.touched.sys_min}
                    errors={formik.errors.sys_min}
                    name={'sys_min'}
                  />
                </div>
                <div className='col-6 col-md-2'>
                  <CustomInput
                    formikFields={formik.getFieldProps('sys_max')}
                    label={'SYS Max'}
                    type={'number'}
                    touched={formik.touched.sys_max}
                    errors={formik.errors.sys_max}
                    name={'sys_max'}
                  />
                </div>
              </div>
              <div className={'col-12 mb-2'}>
                <button className={'btn btn-sm btn-light-primary'} type={'submit'}>
                  {intl.formatMessage({id: 'Guardar'})}
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </>
  )
}

export default BloodPressurePage
