import React, {FC} from 'react'
import {Link, Navigate, useLocation, useParams} from 'react-router-dom'
import PageTitle from '../../_components/PageTitle'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Patient} from '../../../../models/_patient'
import {useAuth} from '../../../modules/auth'
import {havePerm, haveRole} from '../../../_helpers'

interface Breadcrumb {
  title: string
  division: string
  user: Patient
}

const ToolBar: FC<Breadcrumb> = (props): JSX.Element => {
  const location = useLocation()
  const intl = useIntl()
  const {id} = useParams()
  const {auth} = useAuth()

  if (haveRole('patient', auth)) return <Navigate to='/error/404' />

  return (
    <>
      <PageTitle title={props.title} division={props.division} />
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-gray-800 fs-2 fw-bolder me-1'>{props.user.full_name}</p>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 me-1'>
                  <p className='d-flex align-items-center text-gray-400  me-5 mb-2'>
                    <i className={'fa-id-card fa-solid mr-2'}></i> {props.user.id}
                  </p>
                  <p className='d-flex align-items-center text-gray-400  mb-2 me-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {props.user.email}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {havePerm('collaborators:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/collaborator/perfil/' + id && 'active')
                    }
                    to={'/collaborator/perfil/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-id-card me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Perfil'})}
                  </Link>
                </li>
              )}
              {havePerm('appointments:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/collaborator/schedules/' + id && 'active')
                    }
                    to={'/collaborator/schedules/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-calendar-days me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Agenda'})}
                  </Link>
                </li>
              )}
              {havePerm('attachments:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/collaborator/attachments/' + id && 'active')
                    }
                    to={'/collaborator/attachments/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-paperclip me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Anexos'})}
                  </Link>
                </li>
              )}
              {(haveRole('admin', auth) || haveRole('super-admin', auth)) && (
                <li className='nav-item '>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname.includes('/collaborator/tecnical/' + id) && 'active')
                    }
                    to={'/collaborator/tecnical/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-gear me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Definições'})}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default ToolBar
