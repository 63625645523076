import React, {useEffect, useState} from 'react'
import {useOutletContext, useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {API_URL} from '../../../_const'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

// @ts-ignore
import logo from '../../../assets/ecg.gif'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {toastStyles} from '../../../_styles'
import CustomInput from '../../_components/CustomInput'
import {warningValuesSchema} from '../../../../schemas/_records'
import {WarningValue} from '../../../../models/_warningRecords'
import {GlucosePoint} from '../../../../models/_bloodGlucose'
import {havePerm} from '../../../_helpers'
import {useAuth} from '../../../modules/auth'

function BloodGlucosePage() {
  const intl = useIntl()
  const {id} = useParams()
  const {auth} = useAuth()
  const [data, setData] = useState<GlucosePoint[]>([])
  const [initialValues, setInitialValues] = useState<WarningValue>({} as WarningValue)
  const interval: Date[] = useOutletContext()
  const [warnings, setWarnings] = useState<{icon: string; bg: GlucosePoint; text: string}>({
    icon: 'face-smile',
    bg: {} as GlucosePoint,
    text: 'success',
  })

  useEffect(() => {
    const fetchData = async () => {
      const date_start: string =
        interval[0].getFullYear() +
        '-' +
        (interval[0].getMonth() + 1 >= 10
          ? interval[0].getMonth() + 1
          : '0' + (interval[0].getMonth() + 1)) +
        '-' +
        (interval[0].getDate() >= 10 ? interval[0].getDate() : '0' + interval[0].getDate())
      const date_end: string =
        interval[1].getFullYear() +
        '-' +
        (interval[1].getMonth() + 1 >= 10
          ? interval[1].getMonth() + 1
          : '0' + (interval[1].getMonth() + 1)) +
        '-' +
        (interval[1].getDate() >= 10 ? interval[1].getDate() : '0' + interval[1].getDate())
      const data_temp: GlucosePoint[] = []

      const res: AxiosResponse<GlucosePoint[]> = await axios.get(
        `${API_URL}/blood-glucoses?user_id=${id}&date_start=${date_start} 00:00:00&date_end=${date_end} 23:59:59`
      )
      res.data.forEach((point, index) => {
        data_temp.push(point)
      })
      setData(data_temp)
      if (havePerm('warningvalues:read', auth)) {
        const {data}: AxiosResponse<{data: WarningValue}> = await axios.get(
          `${API_URL}/warning-values/${id}`
        )
        setInitialValues(data.data)
      }

      if (res.data.length > 0) showWarning(data_temp[data_temp.length - 1])
    }

    if (interval[0] && interval[1]) fetchData()
  }, [interval])

  useEffect(() => {
    const fetchData = async () => {
      showWarning(data[data.length - 1])
    }
    fetchData()
  }, [initialValues])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: warningValuesSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${API_URL}/warning-values`, {
          bs_min: values.bs_min ?? null,
          bs_max: values.bs_max ?? null,
          user_id: id,
        })
        .then((res) => {
          setInitialValues({...values})
          toast.success(intl.formatMessage({id: 'Intervalo Definido'}), toastStyles)
        })
        .catch((err) => {
          toast.error(intl.formatMessage({id: err.response.data.message}), toastStyles)
        })
    },
  })

  const showWarning = (value: GlucosePoint) => {
    if (initialValues.bs_min || initialValues.bs_max) {
      if ((initialValues.bs_min ?? 0) > value.bs || (initialValues.bs_max ?? 2000) < value.bs) {
        setWarnings({
          bg: value,
          text: 'danger',
          icon: 'face-frown',
        })
      } else {
        setWarnings({
          bg: value,
          text: 'success',
          icon: 'face-smile',
        })
      }
    } else {
      setWarnings({
        bg: value,
        text: 'success',
        icon: 'face-smile',
      })
    }
  }

  return (
    <>
      {data.length != 0 ? (
        <>
          {havePerm('warningvalues:read', auth) && data.length !== 0 && warnings.bg && (
            <div className='row m-0 m-md-11 mt-md-0'>
              <span>Data Selecionada: {warnings.bg.x}</span>
              <div className='col-12 col-md-4 mb-2'>
                <div className={`rounded shadow`}>
                  <div className='d-flex flex-start p-3 w-100'>
                    <i
                      className={`fa-solid fa-${warnings.icon}  text-${warnings.text} m-2`}
                      style={{fontSize: '4em'}}
                    ></i>
                    <div className={'d-flex flex-column p-2'}>
                      <span
                        className={'text-uppercase text-muted'}
                        style={{fontSize: '1.25em', fontWeight: 700, lineHeight: 'normal'}}
                      >
                        {intl.formatMessage({id: 'Glucose'})}
                      </span>
                      <span style={{fontSize: '2em', fontWeight: 'bold', lineHeight: 'initial'}}>
                        {warnings.bg.bs}
                        <span style={{fontSize: '0.5em'}}> mg/dl</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className=' h-300px scroll'>
            <ResponsiveContainer height='100%' width='100%' className={'scroll'}>
              <LineChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                onClick={({activePayload}) => {
                  showWarning(activePayload ? activePayload[0].payload : {})
                }}
              >
                <CartesianGrid strokeDasharray='2 2' />
                <Tooltip
                  content={({active, payload, label}) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className='d-flex flex-column flex-wrap bg-opacity-75 bg-white p-3'>
                          {payload[0].payload.x}
                          <span className='label'>{`Glucose: ${payload[0].value}`}</span>
                        </div>
                      )
                    }

                    return null
                  }}
                />
                <YAxis />
                <Legend />
                <Line type='monotone' dataKey='bs' stroke='#009EF7' dot={false} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        <>
          <div className='w-100 h-100 d-flex justify-content-center'>
            <img src={logo} alt='' />
          </div>
          <h3 className={'text-center text-muted w-100'}>
            {intl.formatMessage({id: 'Sem Registos'})}
          </h3>
        </>
      )}
      {havePerm('warningvalues:read', auth) && data.length !== 0 && (
        <div className='row'>
          <div className='col-12'>
            <hr />
            <h4>{intl.formatMessage({id: 'Avisos'})}</h4>
          </div>
          {havePerm('warningvalues:update', auth) && (
            <form onSubmit={formik.handleSubmit}>
              <div className='row'>
                <div className='col-12 col-md-2 '>
                  <CustomInput
                    formikFields={formik.getFieldProps('bs_min')}
                    label={'Glucose Min'}
                    type={'number'}
                    touched={formik.touched.bs_min}
                    errors={formik.errors.bs_min}
                    name={'bs_min'}
                  />
                </div>
                <div className='col-12 col-md-2 '>
                  <CustomInput
                    formikFields={formik.getFieldProps('bs_max')}
                    label={'Glucose Max'}
                    type={'number'}
                    touched={formik.touched.bs_max}
                    errors={formik.errors.bs_max}
                    name={'bs_max'}
                  />
                </div>
              </div>
              <button className={'btn btn-sm btn-light-primary'} type={'submit'}>
                {intl.formatMessage({id: 'Guardar'})}
              </button>
            </form>
          )}
        </div>
      )}
    </>
  )
}

export default BloodGlucosePage
